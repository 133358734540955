import React from "react";
import { Link } from "react-router-dom";
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";
import image1 from "./s-blogimg-01.png";
import image2 from "./s-blogimg-02.png";
function Footer({ uni }) {
  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling animation
    });
  };
  return (
    <>
      {/* <NewsLetter/> */}
      <div className="w-full   md:pt-[70px] pt-[10px] md:px-3 px-2 bg-[#444444]">
        <div className="container mx-auto">
          <div className="flex justify-between flex-wrap">
            {/* About Us Section Start */}
            <div className="w-full mb-2 lg:mb-0 sm:w-[100%] md:w-[48%] lg:w-[23%]">
              <h4 className="text-[#AAAAAA] py-2 border_orange font-bold">
                About Us
              </h4>
              <p className="text-white">
                Parul University supports its students to succeed at every level
                to become well-rounded individuals and skilled professionals.
                The Trust runs six colleges–Unitedworld Institute of Design,
                Unitedworld School of Business, Unitedworld School of Liberal
                Arts & Mass Communication, Unitedworld School of Law,
                Unitedworld School of Computational Intelligence and Parul
                School of Dentistry
              </p>
              <div className="flex gap-3 py-2">
                <div
                  className="flex items-center justify-center bg-opacity-60 bg-[#ffffff47]
                 w-[36px] h-[36px] rounded-full hover:bg-red-400 py-1">
                  <FaFacebookF className="text-[rgb(255,223,21)]" />
                </div>
                <div
                  className="flex items-center justify-center bg-opacity-60 bg-[#ffffff47]
                 w-[36px] h-[36px] rounded-full hover:bg-red-400 py-1">
                  <FaInstagram className="text-[rgb(255,223,21)]" />
                </div>
                <div
                  className="flex items-center justify-center bg-opacity-60 bg-[#ffffff47]
                 w-[36px] h-[36px] rounded-full hover:bg-red-400 py-1">
                  <FaTwitter className="text-[rgb(255,223,21)]" />
                </div>
              </div>
            </div>

            {/* EnD About Us Section Start */}

            {/* Latest Post Start */}
            <div className="w-full mb-2 lg:mb-0 sm:w-[100%] md:w-[48%] lg:w-[23%]">
              <h4 className="text-[#AAAAAA] py-2 border_orange font-bold">
                Latest Post
              </h4>

              <Link to="/blogpost1" onClick={handleClick}>
                <div className="flex py-2 gap-2">
                  <div>
                    <img src={image1} alt="footer_img" />
                  </div>
                  <div className="ml-2">
                    <p className="mb-1">
                      <a href="#" className="no-underline text-white">
                        Parul University's Journey of Empowering Future Leaders
                      </a>
                    </p>
                    <span className="text-[#AAAAAA]-400">15 March,2024</span>
                  </div>
                </div>
              </Link>
              <Link to="/blogpost2" onClick={handleClick}>
                <div className="flex gap-2">
                  <div>
                    <img src={image2} alt="footer_img" />
                  </div>
                  <div className="ml-1">
                    <p className="mb-1">
                      <a href="#" className="no-underline text-white">
                        Why Parul University Is the Top Choice for Future
                        Leaders
                      </a>
                    </p>
                    <span className="text-[#AAAAAA]-400">20 march,2024</span>
                  </div>
                </div>
              </Link>
            </div>

            {/* Latest Post EnD */}

            {/* Our Links Start*/}

            <div className="w-full mb-2 lg:mb-0 sm:w-[100%] md:w-[70%] lg:w-[40%] ml-2">
              <h4 className="text-[#AAAAAA] py-2  font-bold  text-center underline-offset-2">
                Our Links
              </h4>
              <div className=" border-t-[#ff7350]  py-2  text-center border-t  flex justify-center"></div>
              <div className="w-full flex justify-between gap-8">
                <ul className="pl-0 text-white">
                  <li>
                    <a href="# text-white no-underline">
                    parul university


                    </a>
                  </li>
                  <li>
                    <a href="# text-white no-underline">
                    parul university fees

                    </a>
                  </li>
                  <li>
                    <a href="# text-white no-underline">
                    parul university vadodara

                    </a>
                  </li>

                  <li>
                    <a href="# text-white no-underline">
                    parul university mba fees

                    </a>
                  </li>
                  <li>
                    <a href="# text-white no-underline">
                    parul university jaipur

                    </a>
                  </li>
                  <li>
                    <a href="# text-white no-underline">
                    parul university hostel fees

                    </a>
                  </li>
                  <li>
                    <a href="# text-white no-underline">
                    parul university contact no

                    </a>
                  </li>
                  <li>
                    <a href="# text-white no-underline">
                    parul university fees structure

                    </a>
                  </li>
                  <li>
                    <a href="# text-white no-underline">
                    parul university courses and fees

                    </a>
                  </li>
                  <li>
                    <a href="# text-white no-underline">
                    parul university vadodara fees

                    </a>
                  </li>
                  <li>
                    <a href="# text-white no-underline">
                    parul university course admissions

                    </a>
                  </li>
                  <li>
                    <a href="# text-white no-underline">
                    parul university udaipur

                    </a>
                  </li>
                  <li>
                    <a href="# text-white no-underline">
                    parul university baroda

                    </a>
                  </li>
                
                </ul>
                <ul>
                  <li className="py-1">
                    <a href="#" className="text-white no-underline">
                    parul university mca fees

                    </a>
                  </li>
                  <li className="py-1">
                    <a href="#" className="text-white no-underline">
                    parul university courses

                    </a>
                  </li>
                  <li className="py-1">
                    <a href="#" className="text-white no-underline">
                    parul university b tech fees

                    </a>
                  </li>
                  <li>
                    <a href="#" className="text-white no-underline">
                    parul university bca fees

                    </a>
                  </li>
                  <li>
                    <a href="#" className=" text-white no-underline">
                    parul university gujarat

                    </a>
                  </li>
                  <li>
                    <a href="#" className="text-white no-underline">
                    parul university admission process

                    </a>
                  </li>
                  <li>
                    <a href="#" className="text-white no-underline">
                    parul university btech cse fees

                    </a>
                  </li>
                  <li className="py-1">
                    <a href="#" className="text-white no-underline">
                    parul university rajasthan

                    </a>
                  </li>
                  <li className="py-1">
                    <a href="#" className="text-white no-underline">
                    parul university vadodara gujarat

                    </a>
                  </li>
                  <li className="py-2">
                    <a href="#" className="text-white no-underline">
                    parul university admission

                    </a>
                  </li>
                  <li>
                    <a href="#" className="text-white no-underline">
                    parul university fee structure
                    </a>
                  </li>
                  
                 
               
                </ul>
              </div>
            </div>

            {/* Our Links EnD*/}

            {/* Contact Us Start */}

            {/* Contact Us EnD*/}
          </div>
        </div>
        <div className="w-full  lg:mb-0 sm:w-[100%] md:w-[100%] lg:w-[100%] md:mt-[2rem] mb-[2rem] md:px-4 px-2 py-8">
          <p className="text-white text-[12px]">
            <span className="font:bold text-[18px] text-[#AAAAAA] font-bold">
              Disclaimer -{" "}
            </span>{" "}
            The purpose of aaopadhe is to provide accurate and unbiased
            information about universities and their programs of study to
            admission aspirants. The content on the aaopadhe website, including
            texts, graphics, images, blogs, videos, university logos, and other
            materials (collectively referred to as "Content"), is provided for
            informational purposes only. The content is not intended to replace
            or substitute for any offerings or services provided by our academia
            partners. We do not endorse or deliberately infringe upon any
            intellectual property or associated rights of . The information
            provided by aaopadhe on www.aaoapdhe.in, as well as any mobile or
            other applications, is offered in good faith and to the best of our
            knowledge and abilities. However, we make no representation or
            warranty, whether express or implied, regarding the accuracy,
            adequacy, validity, reliability, or completeness of the information
            provided on the website or in our applications. While we strive to
            maintain accurate and up-to-date information, errors or omissions
            may occur. Users can also visit the official website of for the most
            up-to-date and accurate information regarding admissions, programs,
            courses, fees, deadlines, and any other relevant details. By using
            the aaopadhe website or any of our applications, you acknowledge
            that you have read, understood, and agreed to the terms and
            conditions of this disclaimer. If you do not agree with any part of
            this disclaimer, we kindly request that you refrain from using our
            platform. Please be aware that this disclaimer may be subject to
            change without prior notice. We recommend reviewing the disclaimer
            periodically for any updates or modifications. If you have any
            questions or concerns regarding this disclaimer, please contact us.
          </p>
        </div>
      </div>
    </>
  );
}

export default Footer;
