import React, { useState } from "react";

function UniFaq({uni}) {
  const [activeIndex0, setActiveIndex0] = useState(null);
  const [activeIndex1, setActiveIndex1] = useState(null);
  const [activeIndex2, setActiveIndex2] = useState(null);
  const [activeIndex3, setActiveIndex3] = useState(null);
  const [activeIndex4, setActiveIndex4] = useState(null);

  const toggleAccordion0 = () => {
    setActiveIndex0((prevIndex) => (prevIndex === 0 ? null : 0));
  };
  const toggleAccordion1 = () => {
    setActiveIndex1((prevIndex) => (prevIndex === 1 ? null : 1));
  };
  const toggleAccordion2 = () => {
    setActiveIndex2((prevIndex) => (prevIndex === 2 ? null : 2));
  };
  const toggleAccordion3 = () => {
    setActiveIndex3((prevIndex) => (prevIndex === 3 ? null : 3));
  };
  const toggleAccordion4 = () => {
    setActiveIndex4((prevIndex) => (prevIndex === 4 ? null : 4));
  };

  return (
    <>
      <section className="text-white w-full py-12 pb-[6rem] bg-slate-700 flex justify-center" >
      
        <div className="md:w-[80%] w-[90%]">
          <p className="font-bold text-white md:text-4xl text-2xl text-center py-5">
            Let`s clear some doubts
          </p>
          <div className="grid gap-2 rounded-md ">
            {/* right section start */}
            <div className="col-span-2 px-2">
              {/* 1st ques start */}
              <div
                className={`border-2 border-white p-4 mb-3 flex items-center ${
                  activeIndex0 === 0 ? "text-black" : "text-[#fff] "
                }`}
                onClick={toggleAccordion0}
              >
                <span className="hover:text-[0D1282] text-white font-bold text-xl">
                  {uni && uni.doubtSection[0].question}
                </span>
                <span className="text-[#1BCCBA] ml-auto">
                  {activeIndex0 === 0 ? "-" : "+"}
                </span>
              </div>
              {/*  border-[#7D7C7C] */}
              {activeIndex0 === 0 && (
                <div className="p-2 border border-white mb-3 ">
                  {/* Content for the 1st question */}
                  <ul className="list-disc px-6 font-light">
                    <li>{uni && uni.doubtSection[0].answer1}</li>
                    {uni && uni.answer2 == null ? (
                      ""
                    ) : (
                      <li>{uni && uni.doubtSection[0].answer2}</li>
                    )}
                  </ul>
                </div>
              )}
              {/* 1st ques end */}
              {/* 2nd ques start */}
              <div
                className={`border-2  border-white p-4 mb-3 flex items-center ${
                  activeIndex1 === 1 ? "text-[#1BCCBA]" : "text-[#fff] "
                }`}
                onClick={toggleAccordion1}
              >
                <span className="hover:text-[0D1282] text-white font-bold text-xl">
                  {uni && uni.doubtSection[1].question}
                </span>
                <span className="text-[#1BCCBA] ml-auto">
                  {activeIndex1 === 1 ? "-" : "+"}
                </span>
              </div>

              {activeIndex1 === 1 && (
                <div className="p-2 border mb-3  border-white">
                  {/* Content for the 2nd question */}
                  <ul className="list-disc px-6 font-light">
                    <li>{uni && uni.doubtSection[1].answer1}</li>
                    {uni && uni.answer2 == null ? (
                      ""
                    ) : (
                      <li>{uni && uni.doubtSection[1].answer2}</li>
                    )}
                  </ul>
                </div>
              )}
              {/* 2nd ques end */}
              {/* 3rd ques start */}
              <div
                className={`border-2  border-white p-4 mb-3 flex items-center ${
                  activeIndex2 === 2 ? "text-[#1BCCBA]" : "text-[#fff] "
                }`}
                onClick={toggleAccordion2}
              >
                <span className="hover:text-[0D1282] text-white font-bold text-xl">
                  {uni && uni.doubtSection[2].question}
                </span>
                <span className="text-[#1BCCBA] ml-auto">
                  {activeIndex2 === 2 ? "-" : "+"}
                </span>
              </div>

              {activeIndex2 === 2 && (
                <div className="p-2 border mb-3  border-white">
                  {/* Content for the 3rd question */}
                  <ul className="list-disc px-6 font-light">
                    <li>{uni && uni.doubtSection[2].answer1}</li>
                    {uni && uni.answer2 == null ? (
                      ""
                    ) : (
                      <li>{uni && uni.doubtSection[2].answer2}</li>
                    )}
                  </ul>
                </div>
              )}
              {/* 3rd ques end */}
              {/* 4th ques start */}
              <div
                className={`border-2  border-white p-4 mb-3 flex items-center ${
                  activeIndex3 === 3 ? "text-[#1BCCBA]" : "text-[#fff] "
                }`}
                onClick={toggleAccordion3}
              >
                <span className="hover:text-[0D1282] text-white font-bold text-xl">
                  {uni && uni.doubtSection[3].question}
                </span>
                <span className="text-[#1BCCBA] ml-auto">
                  {activeIndex3 === 3 ? "-" : "+"}
                </span>
              </div>

              {activeIndex3 === 3 && (
                <div className="p-2 border mb-3  border-white">
                  {/* Content for the 4th question */}
                  <ul className="list-disc px-6 font-light">
                    <li>{uni && uni.doubtSection[3].answer1}</li>
                    {uni && uni.answer2 == null ? (
                      ""
                    ) : (
                      <li>{uni && uni.doubtSection[3].answer2}</li>
                    )}
                  </ul>
                </div>
              )}
              {/* 4th ques end */}
              {/* 5th ques start */}
              <div
                className={`border-2  border-white p-4 mb-3 flex items-center ${
                  activeIndex4 === 4 ? "text-[#1BCCBA]" : "text-[#fff] "
                }`}
                onClick={toggleAccordion4}
              >
                <span className="hover:text-[0D1282] text-white font-bold text-xl">
                  {uni && uni.doubtSection[4].question}
                </span>
                <span className="text-[#1BCCBA] ml-auto">
                  {activeIndex4 === 4 ? "-" : "+"}
                </span>
              </div>

              {activeIndex4 === 4 && (
                <div className="p-2 border mb-3  border-white">
                  {/* Content for the 5th question */}
                  <ul className="list-disc px-6 font-light">
                    <li>{uni && uni.doubtSection[4].answer1}</li>
                    {uni && uni.answer2 == null ? (
                      ""
                    ) : (
                      <li>{uni && uni.doubtSection[4].answer2}</li>
                    )}
                  </ul>
                </div>
              )}
              {/* 5th ques end */}
            </div>
            {/* right section end */}
          </div>
        </div>
        {/* second section end */}
      </section>
    </>
  );
}

export default UniFaq;
